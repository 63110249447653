<template>
  <div class="text-center">
    <b-spinner label="Loading..." />
    <p>Aguarde, efetuando login</p>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LocalStorageService from '@/services/localStorageService'
import {
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
  mixins: [],
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
    if (this.$router.currentRoute.query.acesso_direto !== undefined) {
      useJwt.login({
        token: this.$router.currentRoute.query.acesso_direto,
      }).then(response => {
        const userData = response.data.data.user
        userData.ability = [{
          action: 'manage',
          subject: 'all',
        }]
        userData.role = 'admin'

        useJwt.setToken(response.data.data.token)
        useJwt.setRefreshToken(response.data.data.token)
        LocalStorageService.setArray(LocalStorageService.userData, userData)

        this.$ability.update(userData.ability)

        this.$router.replace({ name: 'cms-dashboard' })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Bem-vindo ${userData.name}!`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Você está logado como ${userData.role}. Faça um bom uso!`,
              },
            })
          })
      })
    }
  },
}
</script>

<style lang="scss">
.text-center{
  margin-top: 40px;
  p{
    margin-top: 5px;
  }
}
</style>
